<template>
  <!--聊天互动-->
  <div
    class="wxq_chat"
    :style="{ height: fileId == 0 || fileId == '' ? '100%' : '626px' }"
  >
    <p class="wxq_chat_title bg_blue">聊天互动</p>
    <div class="wxq_chat_list">
      <ul class="wxq_list" id="scroll">
        <li v-show="isMore">
          <el-divider content-position="center">以下是全部聊天内容</el-divider>
        </li>
        <li
          v-show="!isCompleted"
          class="gl_more color_blue"
          @click="getMoreMessageList"
        >
          <span>查看更多消息</span>
        </li>
        <li
          v-for="(item, index) in messageList"
          :key="index"
          :class="{ wxq_self: item.from == IMSign.txLoginId }"
        >
          <p class="wxq_time">{{ dateFormat(item.time) }}</p>
          <!-- <template v-if="item.userInfo">
            <img
              :src="
                item.userInfo.avatar != '' ? item.userInfo.avatar : headPortrait
              "
              alt="头像"
            />
            <div class="wxq_message">
              <p class="wxq_name">{{ item.userInfo.realName }}</p>
              <p class="wxq_conversation bg_blue" v-if="item.payload.text">
                {{ item.payload.text }}
              </p>
            </div>
          </template> -->
          <template>
            <img
              :src="item.avatar != '' ? item.avatar : headPortrait"
              alt="头像"
            />
            <div class="wxq_message">
              <p class="wxq_name">{{ item.nick }}</p>
              <p class="wxq_conversation bg_blue" v-if="item.payload.text">
                {{ item.payload.text }}
              </p>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <!-- 审核不可以发送信息 -->
    <div class="wxq_chat_input" v-if="review != 'courseReview'">
      <el-input
        maxlength="450"
        :rows="4"
        placeholder="一起来聊天吧"
        v-model.trim="chatInput"
      ></el-input>
      <el-button class="btn btn_blue" @click="sendContent">发 送</el-button>
    </div>
  </div>
</template>

<script>
import API from "@/apis/studentsEndAPI/liveMenuDetail";
import moment from "moment";
import TIM from "tim-js-sdk";
export default {
  name: "chat",
  props: ["groupId", "fileId", "review"],
  data() {
    return {
      headPortrait: require("@/assets/img/studentImg/liveDetail/tim-avatar.png"),
      prefix: "",
      isLoading: false,
      isCompleted: true,
      isMore: false,
      messageList: [], //消息列表
      chatInput: "", //消息输入框
      IMSign: {},
      timData: {
        userID: "",
        userSig: "",
      },
      messageData: {
        conversationID: "",
        nextReqMessageID: "",
        count: 15,
      },
      profile: {},
    };
  },
  watch: {
    messageList: {
      handler(newVal) {
        this.$nextTick(() => {
          if (this.isMore) this.isLoading = false;
          if (this.isLoading) return;
          var div = document.getElementById("scroll");
          div.scrollTop = div.scrollHeight;
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.IMSign = JSON.parse(
      sessionStorage.getItem("IMSign") || localStorage.getItem("IMSign")
    );
    this.profile = JSON.parse(sessionStorage.getItem("profile"));
    this.timData.userID = this.IMSign.txLoginId;
    this.timData.userSig = this.IMSign.sign;
    this.prefix = this.IMSign.txPrefix;
    this.getTimLogin();
    this.messageListener();
    this.scrollInit();
  },
  destroyed() {
    this.removeListener();
    this.$tim.logout();
  },
  methods: {
    messageListener() {
      this.$tim.on(this.$TIM.EVENT.MESSAGE_RECEIVED, this.getMessageReceived);
      this.$tim.on(this.$TIM.EVENT.SDK_READY, this.getJoinGroup);
      this.$tim.on(this.$TIM.EVENT.SDK_READY, this.updateProfile);
      this.$tim.on(this.$TIM.EVENT.ERROR, this.onError);
      this.$tim.on(this.$TIM.EVENT.KICKED_OUT, this.onKickedOut);
    },
    removeListener() {
      this.$tim.off(this.$TIM.EVENT.MESSAGE_RECEIVED, this.getMessageReceived);
      this.$tim.off(this.$TIM.EVENT.SDK_READY, this.getJoinGroup);
      this.$tim.off(this.$TIM.EVENT.ERROR, this.onError);
      this.$tim.off(this.$TIM.EVENT.KICKED_OUT, this.onKickedOut);
    },
    getMessageReceived(e) {
      let _this = this;
      let data = e.data[0];
      console.log(data);
      _this.messageList.push(data);
    },
    sendContent() {
      //发送
      if (
        sessionStorage.getItem("educationToken") ||
        localStorage.getItem("educationToken")
      ) {
        if (!this.chatInput) {
          this.$message.warning("消息不能为空！");
          return;
        }
        let _this = this;
        let message = _this.$tim.createTextMessage({
          to: _this.groupId,
          conversationType: _this.$TIM.TYPES.CONV_GROUP,
          payload: {
            text: _this.chatInput,
          },
        });
        _this.$tim
          .sendMessage(message)
          .then((res) => {
            let data = res.data.message;
            let userId = data.from.substr(_this.prefix.length);
            _this.getUserName(userId).then((res) => {
              _this.$set(data, "userInfo", res);
            });
            _this.messageList.push(data);
            _this.chatInput = "";
          })
          .catch((error) => {
            _this.$message.error(error);
            // _this.resendMessage(message);
          });
      } else {
        this.$message.warning("请先登录！");
        this.$store.state.isLogin = true;
      }
    },
    getMoreMessageList() {
      this.isLoading = true;
      this.getMessageList();
    },
    async getMessageList() {
      //获取会话列表
      let _this = this;
      _this.messageData.conversationID = `GROUP${_this.groupId}`;
      try {
        let res = await _this.$tim.getMessageList(_this.messageData);
        if (res.code == 0) {
          _this.isCompleted = res.data.isCompleted;
          if (res.data.messageList.length == 0) return;
          let list = res.data.messageList.filter((item) => {
            return item.payload.text || item.payload.imageInfoArray;
          });
          _this.messageData.nextReqMessageID = res.data.nextReqMessageID;
          // list.forEach((item) => {
          //   let userIds = item.from.substr(_this.prefix.length);
          //   _this.getUserName(userIds).then((res) => {
          //     _this.$set(item, "userInfo", res);
          //   });
          // });
          _this.messageList = [].concat(list, _this.messageList);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getTimLogin() {
      //登录腾讯IM
      let _this = this;
      try {
        let promise = await this.$tim.login(this.timData);
        if (promise.code == 0) {
          console.log("登录成功");
        }
      } catch (e) {
        console.warn("login error:", e);
      }
      // promise.then(function (imResponse) {
      //   if (imResponse.data.repeatLogin === true) {
      //     // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
      //     console.log(imResponse.data.errorInfo);
      //   }
      // }).catch(function (imError) {
      //   console.warn('login error:', imError); // 登录失败的相关信息
      // });
    },
    updateProfile() {
      let _this = this;
      let promise = this.$tim.updateMyProfile({
        nick: _this.profile.realName
          ? _this.profile.realName + ""
          : _this.profile.username + "",
        avatar: _this.profile.avatar
          ? _this.profile.avatar
          : _this.headPortrait,
      });
      promise
        .then(function (imResponse) {
          console.log(imResponse.data); // 更新资料成功
        })
        .catch(function (imError) {
          console.log("资料更失败" + imError);
          console.warn("updateMyProfile error:", imError); // 更新资料失败的相关信息
        });
    },
    getJoinGroup() {
      //加入群组
      let _this = this;
      let promise = _this.$tim.joinGroup({
        groupID: _this.groupId,
        type: _this.$TIM.TYPES.GRP_MEETING,
      });
      promise
        .then(function (imResponse) {
          switch (imResponse.data.status) {
            case _this.$TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
              break;
            case _this.$TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
              _this.getMessageList();
              break;
            case _this.$TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
              _this.getMessageList();
              break;
            default:
              break;
          }
        })
        .catch(function (imError) {
          console.warn("joinGroup error:", imError); // 申请加群失败的相关信息
        });
    },
    scrollInit() {
      //滚动条滑动
      let _this = this;
      document.getElementById("scroll").onscroll = function () {
        let scrollHeight = document.getElementById("scroll").scrollTop;
        if (scrollHeight == 0) {
          _this.isMore = false;
        }
        if (scrollHeight == 0 && _this.isCompleted == true) {
          _this.isMore = true;
        }
      };
    },
    async getUserName(params) {
      let res = await API.getUserList(params);
      if (res && !res.status) {
        return res[0];
      } else {
        this.$message.warning(res.errMsg);
      }
    },
    dateFormat(val) {
      //日期转格式
      return moment(val * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    getMemberList() {
      let promise = this.$tim.getGroupMemberList({
        groupID: this.groupId,
        count: 30,
        offset: 0,
      }); // 从0开始拉取30个群成员
      promise
        .then(function (imResponse) {
          debugger;
          console.log(imResponse.data.memberList); // 群成员列表
        })
        .catch(function (imError) {
          console.warn("getGroupMemberList error:", imError);
        });
    },
    onError(e) {
      if (e.data.code == 2999) {
        this.getTimLogin();
      }
    },
    onKickedOut() {},
  },
};
</script>

<style scoped lang="scss">
.wxq_chat {
  width: 100%;
  height: 626px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  .wxq_chat_title {
    height: 40px;
    border-radius: 6px 6px 0 0;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
  }
  .wxq_chat_list {
    height: calc(100% - 100px);
    .wxq_list {
      overflow-y: auto;
      padding: 16px;
      height: 100%;
      .gl_more {
        margin-bottom: 16px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        span {
          padding-left: 18px;
          background: url(../../../../assets/img/studentImg/liveDetail/icon-history.png)
            left center no-repeat;
          background-size: 14px;
        }
      }
      li {
        img,
        .el-image {
          display: block;
          float: left;
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        .wxq_message {
          display: block;
          margin: 0 50px 8px;
          .wxq_name {
            overflow: hidden;
            margin-bottom: 4px;
            height: 20px;
            color: #808080;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
          }
          .wxq_conversation {
            position: relative;
            float: left;
            padding: 8px 18px;
            border-radius: 8px;
            background: #f2f6fd !important;
            &::before {
              position: absolute;
              top: 4px;
              left: -10px;
              display: block;
              border-width: 10px;
              border-style: solid dashed dashed dashed; /*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
              border-color: #f2f6fd transparent transparent transparent;
              content: "";
            }
            img {
              display: block;
              width: 180px;
              height: auto;
              border-radius: 0;
            }
          }
        }
        .wxq_time {
          padding: 6px;
          color: #ccc;
          text-align: center;
          font-size: 12px;
        }
        &::after {
          display: block;
          clear: both;
          content: "";
        }
      }
      .wxq_self {
        img,
        .el-image {
          float: right;
        }
        .wxq_message {
          .wxq_name {
            overflow: hidden;
            height: 20px;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .wxq_conversation {
            position: relative;
            float: right;
            color: #fff;
            background: #3f8cff !important;
            &::before {
              position: absolute;
              top: 4px;
              right: -10px;
              left: auto;
              display: block;
              border-width: 10px;
              border-style: solid dashed dashed dashed; /*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
              border-color: var(--colorBlue) transparent transparent transparent;
              content: "";
            }
          }
        }
      }
    }
  }
  .wxq_chat_input {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    height: 60px;
    .el-input {
      width: 75%;
      ::v-deep.el-input__inner {
        border: none;
        border-radius: 6px;
        background: #ecf4ff;
      }
    }
    input::-webkit-input-placeholder {
      color: #91929e;
    }
    .btn {
      padding: 0 12px;
      border-radius: 6px;
    }
  }
}
</style>
