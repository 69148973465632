<template>
  <!--视频组件-->
  <div class="wxq_video">
    <div class="yh_countDown" v-if="isCount">{{ countDown }}</div>
    <div class="wxq_live_video" id="id_test_video"></div>
    <div v-if="isClose" @click="closeVideo" class="yh_close">关闭</div>
  </div>
</template>

<script>
import moment from "moment";
import {setInterval} from 'timers';
// moment.locale("zh-cn");
export default {
  name: 'liveVideo',
  props: {
    to: {
      type: String,
      required: false,
    },
    coverUrl: {
      type: String,
      // defalut: '',
    },
    liveInfo: {
      type: Object,
      // defalut: {},
    },
    type: {
      type: String,
    },
  },
  watch: {
    to: {
      handler(val) {
        if (val) {
          document.querySelector(val).appendChild(this.$el)
        }
      },
      deep: true,
    },
    liveInfo: {
      handler(val) {
        if (val.livePullM3u8) {
          this.videoPlayer(val)
          this.videoInfo = val
          this.momentCountTime(val.liveStartTime)
          this.count = val.liveStartTime
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      player: '',
      videoInfo: {},
      isShowVideo: true,
      fileGroup: '',
      countDown: '',
      isCount: true,
      count: '',
      timmer: 0,
      isClose: false
    }
  },
  mounted() {
    if (this.to) {
      document.querySelector(this.to).appendChild(this.$el)
    }
    this.$EventBus.$on('sendBus', (val, flag) => {
      if (document.getElementById("id_test_video")) document.getElementById("id_test_video").innerHTML = ""
      if (val) {
        this.fileGroup = val.fileGroup
        this.videoPlayer(val)
      }
      if (flag) {
        this.isClose = true//关闭按钮展示
      }
    })
    // this.videoPlayer()
  },
  methods: {
    videoPlayer(video) {
      let _this = this
      this.player = new TcPlayer('id_test_video', {
        live: _this.type == 'liveType' ? true : false,
        webrtc: video.livePullUdp ? video.livePullUdp : '',
        mp4: video.filePath ? video.filePath : "",
        flv: video.livePullFlv ? video.livePullFlv : '',//http://2157.liveplay.myqcloud.com/live/2157_358535a.flv
        rtmp: video.livePullRtmp ? video.livePullRtmp : '',
        m3u8: video.livePullM3u8 ? video.livePullM3u8 : '',//http://2157.liveplay.myqcloud.com/2157_358535a.m3u8
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: _this.coverUrl ? _this.coverUrl : '',
        pausePosterEnabled: true,
        controls: 'default',
        width: '100%', //视频的显示宽度，请尽量使用视频分辨率宽度
        height: '100%', //视频的显示高度，请尽量使用视频分辨率高度
        wording: {
          2: ' ',
          4: ' '
          // 2032: '请求视频失败，请检查网络',
          // 2048: '请求m3u8文件失败，可能是网络错误或者跨域问题',
        },
        coverpic: {
          // style: 'stretch',
          src: _this.coverUrl
        },
        listener(msg) {
          if (msg.type == 'error') {
            let tipDiv = document.querySelector('.vcp-error-tips')
            let vcpPoster = document.querySelector('.vcp-poster')
            let errorStr = tipDiv.innerText
            vcpPoster.style.display = 'block'
            if (errorStr && errorStr.indexOf(']') >= 0) {
              tipDiv.innerText = ''
            }
            window.setTimeout(function () {
              _this.player.load()
            }, 5000)
          }
        },
      })
      // this.player.play()
    },
    momentCountTime(value) {
      // 定义变量 d,h,m,s保存倒计时的时间
      var d, h, m, s;
      let _this = this
      let now = new Date().getTime()
      // 设置截止时间
      let end = new Date(value).getTime() // this.curStartTime需要倒计时的日期
      // 时间差
      let leftTime = end - now
      if (leftTime >= 0) {
        // 天
        var day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 三元表达式判断不满10前面加0
        d = day < 10 ? '0' + day : day
        //时
        var hour = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        h = hour < 10 ? '0' + hour : hour
        // 分
        var min = Math.floor(leftTime / 1000 / 60 % 60)
        m = min < 10 ? '0' + min : min
        // 秒
        var second = Math.floor(leftTime / 1000 % 60)
        s = second < 10 ? '0' + second : second
        _this.countDown = '距直播开始还有：' + d + '天' + h + '时' + m + '分' + s + '秒'
      } else {
        d = '00'
        h = '00'
        m = '00'
        s = '00'
        this.isCount = false
      }
      // 等于0的时候不调用
      if (Number(_this.hour) === 0 && Number(_this.day) === 0 && Number(_this.min) === 0 && Number(_this.second) === 0) {
        _this.isCount = false
        return
      } else {
        // 递归每秒调用momentCountTime方法，显示动态时间效果,
        this.timmer = setTimeout(() => {
          // debugger
          _this.momentCountTime(_this.count)
        }, 1000)
      }
    },
    closeVideo() {
      this.isClose = false
      if (document.getElementById("id_test_video")) document.getElementById("id_test_video").innerHTML = ""
      this.videoPlayer(this.videoInfo)
    }
  },
  destroyed() {
    if (this.player) {
      this.player.destroy()
    }    
    clearTimeout(this.timmer)
  },
}
</script>

<style scoped lang="scss">
::v-deep {
	.wxq_live_video {
		.vcp-error-tips {
			position: absolute;
			top: 102% !important;
			left: 0;
			z-index: 1001;
			margin-top: -5.25em;
			width: 100%;
			height: 4.5em;
			color: #ff4500;
			text-align: center;
		}
	}
}
.yh_countDown {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	height: 45px;
	background: #000;
	color: #fff;
	text-align: center;
	font-size: 18px;
	line-height: 45px;
}
.wxq_video {
	position: relative;
	width: 100%;
	height: 100%;
	.wxq_live_video {
		height: 100%;
	}
	.yh_close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		font-weight: 700;
		font-size: 20px;
		cursor: pointer;
	}
}

</style>
