<template>
  <div>
    <div class="wxq_live_courseware">
      <el-carousel trigger="click" :autoplay="false">
        <el-carousel-item v-for="(item,index) in images" :key="index">
          <el-image :src="item">
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import coursewareAPI from "@/apis/backStageEndAPI/coursewareManagementAPI";
export default {
  name: 'teleport',
  props: {
    to: {
      type: String,
      required: false
    },
    fileId: {
      type: Number,
      required: false
    }
  },
  watch: {
    to: {
      handler(val) {
        if (val) {
          document.querySelector(val).appendChild(this.$el)
        }
      },
      deep: true
    }
  },
  data() {
    return {
      images: [],
    }
  },
  mounted() {
    document.querySelector(this.to).appendChild(this.$el)
    if (this.fileId) {
      this.getFileDetail(this.fileId)
    }
  },
  methods: {
    getFileDetail(fileId) {
      coursewareAPI.fileDetail(fileId).then(res => {
        this.images = res.docImages.split(',')
      })
    },
  }
}
</script>

<style scoped lang="scss">
div {
  height: 100%;
}
.wxq_live_courseware {
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  .wxq_iframe {
    width: 100%;
    height: 100%;
  }
  ::v-deep.el-carousel {
    height: 100%;
    .el-carousel__container {
      height: 100%;
    }
  }
  .el-image {
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    ::v-deep.el-image__inner {
      height: auto;
    }

  }
  ::v-deep.el-carousel__indicators {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
